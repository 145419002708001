/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Style, SEO and settings
import Helmet from 'react-helmet';
import { Col, css, Grid, Row, up, styled, withTheme } from '@smooth-ui/core-sc';

// Additional Components/Containers
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';
import getReturnUrlPath from '_platform/src/utils/getReturnUrlPath';

import { withSettings } from 'containers/WebApp/SettingsContext';
import bgImage1x from './images/landing-page-bg@1x.jpg';
import bgImage2x from './images/landing-page-bg@2x.jpg';
import logo from './images/Logo-Login@1x.png';

const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '_platform/src/containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginForm" */ '_platform/src/components/LoginForm/LoginForm'
  )
);

const PageContainer = styled.div`
  align-items: center;
  background-image: url(${bgImage1x});
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url(${bgImage2x});
  }
`;

const LoginLogo = styled.h1`
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 1rem;
  padding-top: 0;
  img {
    height: auto;
    width: 222px;

    ${up(
      'md',
      css`
        width: 481px;
      `
    )};
  }
`;

const IntroContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  margin: 1rem;
  justify-content: center;
  padding: 1rem;
  h3 {
    color: #e32726;
  }

  button {
    background-color: #e32726;
    min-width: 100%;
  }
  a {
    color: #1b3d6d;
    &:visited {
      color: #1b3d6d;
    }
    &:hover {
      color: #e32726;
    }
  }
`;

const LoginContainer = styled.div`
  max-width: 500px;

  .reset-password {
    a {
      font-size: 14px;
      font-weight: normal;
    }
  }

  ${up(
    'lg',
    css`
      padding-left: 2rem;
      min-width: 300px;
    `
  )};
`;

const LoginPage = ({ settings }) => (
  <PublicComponent
    redirectOnError={
      // Redirect to returnUrl, if it's not the same as loginPagePath
      (getReturnUrlPath !== settings.settingsApp.loginPagePath &&
        getReturnUrlPath) ||
      '/'
    }
  >
    <Helmet>
      <title>Login</title>
      {/*<meta name="description" content="Page description" />*/}
    </Helmet>

    <PageContainer>
      <Grid className="page-container">
        <IntroContainer>
          <Grid>
            <Row justifyContent="center">
              <Col>
                <LoginLogo className="text--center">
                  <img
                    src={logo}
                    alt={
                      settings.settingsApp && settings.settingsApp.siteName
                        ? settings.settingsApp.siteName
                        : 'ProgramName'
                    }
                  />
                </LoginLogo>
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col xs={12} sm={10} osm={1} lg={6} olg={0}>
                <LoginContainer>
                  <h2>Login to begin</h2>
                  <LoginProvider disableMeta>
                    <LoginForm formLabels={{ labelUserName: 'Username' }} />
                  </LoginProvider>
                </LoginContainer>
              </Col>
              <Col xs={12} sm={10} osm={1} lg={6} olg={0}>
                <h3>
                  Kraft Heinz Foodservice UK welcomes you to the Growth Partners
                  Foodservice Wholesaler Incentive Program for 2023.
                </h3>
                <p className="spacer--bottom--small">
                  <strong>NEED HELP?</strong>
                  <br />
                  If you have any questions, please do not hesitate to contact
                  your local Kraft Heinz representative or email the help desk
                  and one of the team will respond to your enquiry.
                </p>
              </Col>
            </Row>
          </Grid>
        </IntroContainer>
      </Grid>
    </PageContainer>
  </PublicComponent>
);

LoginPage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withTheme(withSettings(LoginPage));
